import classNames from "classnames";
import { WordpressContext } from "context";
import { MouseEvent, useContext, useEffect, useState } from "react";

import styles from "./HamBurger.module.scss";

export function HamBurger({ menuOpen, onClick, overrideClassName }: IProps) {
    const [active, setActive] = useState(false);
    const { hasPrimaryCover } = useContext(WordpressContext);

    useEffect(() => {
        if (!menuOpen) setActive(false);
    }, [menuOpen]);

    function toggle(e: MouseEvent) {
        setActive(!active);
        onClick(e);
    }

    return (
        <button
            className={classNames(
                styles.hamburger,
                overrideClassName,
                active && styles.active,
                hasPrimaryCover && styles.primaryCover,
            )}
            type="button"
            onClick={toggle}
        >
            <span />
            <span />
            <span />
        </button>
    );
}

interface IProps {
    menuOpen?: boolean;
    onClick: (e: MouseEvent) => void;
    overrideClassName: string;
}
