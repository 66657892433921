import classNames from "classnames";
import { Button } from "components/Button";
import { ViewportContext } from "context";
import { useContext, useEffect, useState } from "react";

import styles from "./ScrollToTop.module.scss";

export function ScrollToTop() {
    const [visible, setVisible] = useState(false);
    const { isTabletUp } = useContext(ViewportContext);

    useEffect(() => {
        document.addEventListener("scroll", toggleVisible);
        return () => {
            document.removeEventListener("scroll", toggleVisible);
        };
    }, []);

    function toggleVisible() {
        const scrolled = document.documentElement.scrollTop;

        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    }

    function scrollToTop() {
        window.scrollTo({
            behavior: "smooth",
            top: 0,
        });
    }

    return (
        <div className={classNames(styles.scrollToTop, visible && styles.visible)}>
            <Button overrideClassName={styles.button} variant="tertiary" onClick={scrollToTop}>
                {isTabletUp && "Back to top"}
            </Button>
        </div>
    );
}
