import classNames from "classnames";
import { Nav } from "components";
import { MenuContext, WordpressContext } from "context";
import { useContext } from "react";

import styles from "./TabbedMenu.module.scss";

export function TabbedMenu() {
    const { mainMenu } = useContext(MenuContext);
    const { hasPrimaryCover } = useContext(WordpressContext);

    return (
        <nav className={classNames(styles.tabbedMenu, hasPrimaryCover && styles.primaryCover)}>
            <Nav
                activeClassName={styles.active}
                items={mainMenu}
                overrideClassName={styles.nav}
                subNavActiveClassName={styles.subNavActive}
            />
        </nav>
    );
}
