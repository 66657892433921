import classNames from "classnames";
import { Nav } from "components";
import { MenuContext, WordpressContext } from "context";
import { useContext } from "react";

import styles from "./TopMenu.module.scss";

export function TopMenu() {
    const { topMenu } = useContext(MenuContext);
    const { hasPrimaryCover } = useContext(WordpressContext);

    return (
        <Nav
            activeClassName={styles.active}
            items={topMenu}
            overrideClassName={classNames(styles.topMenu, hasPrimaryCover && styles.primaryCover)}
        />
    );
}
