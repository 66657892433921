import { ISassVars } from "types";

import styles from "./sassVars.module.scss";

function processSassVars() {
    const keys = Object.keys(styles);
    const newStyles = {};

    keys.forEach((key) => {
        const item = styles[key];
        newStyles[key] = Number.isNaN(parseInt(item, 10))
            ? item.replace(/[\\"]/g, "")
            : parseInt(item, 10);
    });

    return newStyles;
}

export const sassVars = processSassVars() as ISassVars;
