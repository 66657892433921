import CookieConsent from "react-cookie-consent";

export function CookieBanner() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{
                background: "#005500",
                bottom: "10px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
                left: "50%",
                maxWidth: "1240px",
                transform: "translateX(-50%)",
            }}
            buttonStyle={{
                backgroundColor: "#00a800",
                borderRadius: "6px",
                color: "white",
                fontSize: "13px",
                padding: "10px 10px",
            }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}
