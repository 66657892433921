import { getMenus } from "api";
import { AuthContext, LoadingContext } from "context";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { TMenuItems } from "types";

export const MenuContext = createContext<IValue>({
    mainMenu: null,
    topMenu: null,
});

export function MenuProvider({ children }: IProps) {
    const { authToken, logout, user } = useContext(AuthContext);
    const { hasLoaded, haveMenusLoaded } = useContext(LoadingContext);
    const [currentMainMenu, setCurrentMainMenu] = useState(null);
    const [currentTopMenu, setCurrentTopMenu] = useState(null);

    useEffect(() => {
        haveMenusLoaded(currentMainMenu !== null && currentTopMenu !== null);
    }, [currentMainMenu, currentTopMenu]);

    useEffect(() => {
        haveMenusLoaded(false);

        (async () => {
            const response = await getMenus(authToken);

            if (!response) {
                logout(true);
            } else {
                const { mainMenu, topMenu } = response;

                setCurrentMainMenu(mainMenu);
                setCurrentTopMenu(topMenu);
            }
        })();
    }, [user]);

    const value = useMemo(
        () => ({
            mainMenu: currentMainMenu,
            topMenu: currentTopMenu,
        }),
        [hasLoaded],
    ) as IValue;

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

interface IProps {
    children: ReactNode;
}

interface IValue {
    mainMenu: TMenuItems;
    topMenu: TMenuItems;
}
