import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

export const LoadingContext = createContext({
    hasLoaded: false,
    hasThePageLoaded: (isTrue: boolean) => isTrue,
    haveMenusLoaded: (isTrue: boolean) => isTrue,
    initialLoad: "yes",
    initialLoading: (confirmed: boolean) => confirmed,
    isLoading: (forced?: boolean) => forced,
});

export function LoadingProvider({ children }: IProps) {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState<TInitialLoad>("yes");
    const [menusHaveLoaded, setMenusHaveLoaded] = useState<boolean>(false);
    const [pageHasLoaded, setPageHasLoaded] = useState<boolean>(false);

    useEffect(() => setHasLoaded(menusHaveLoaded && pageHasLoaded), [
        menusHaveLoaded,
        pageHasLoaded,
    ]);

    const hasThePageLoaded = (status: boolean) => setPageHasLoaded(status);
    const haveMenusLoaded = (status: boolean) => setMenusHaveLoaded(status);
    const initialLoading = (confirmed: boolean) => setInitialLoad(confirmed ? "yes" : "no");

    const isLoading = (forced?: boolean) => {
        setHasLoaded(false);
        hasThePageLoaded(false);
        if (forced) initialLoading(true);
    };

    const value = useMemo(
        () => ({
            hasLoaded,
            hasThePageLoaded,
            haveMenusLoaded,
            initialLoad,
            initialLoading,
            isLoading,
        }),
        [hasLoaded, initialLoad],
    ) as IValue;

    return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

type TInitialLoad = "no" | "yes";

interface IProps {
    children: ReactNode;
}

interface IValue {
    hasLoaded: boolean;
    hasThePageLoaded: (value: boolean) => boolean;
    haveMenusLoaded: (value: boolean) => boolean;
    initialLoad: TInitialLoad;
    initialLoading: (value: boolean) => boolean;
    isLoading: (value?: boolean) => boolean;
}
