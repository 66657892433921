import classNames from "classnames";
import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { IMenuItems } from "types";
import { isNavActive, keys } from "utils";

export function SubNav(props: IProps) {
    const { activeClassName, item, onClick, openSubNav } = props;

    return (
        <ul>
            {item.childItems?.nodes.map(
                (childItem: IMenuItems) =>
                    !childItem.uri.includes("page_id") && (
                        <li key={keys("subnav", childItem.uri)}>
                            <Link
                                to={childItem.uri}
                                className={classNames(
                                    isNavActive(childItem.uri) && activeClassName,
                                )}
                                onClick={onClick}
                                onFocus={openSubNav}
                                onMouseOver={openSubNav}
                            >
                                {childItem.label}
                            </Link>
                        </li>
                    ),
            )}
        </ul>
    );
}

interface IProps {
    activeClassName: string;
    item: IMenuItems;
    onClick?: (e: MouseEvent) => void;
    openSubNav: () => void;
}
