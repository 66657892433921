import classNames from "classnames";
import { SubNav } from "components";
import { isEmpty } from "lodash";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { IMenuItems } from "types";
import { isNavActive } from "utils";

export function NavLink({ activeClassName, item, onClick, subNavActiveClassName }: IProps) {
    const [subNavActive, setSubNavActive] = useState<boolean>(false);

    function onLinkClick(e: MouseEvent) {
        if (onClick) onClick(e);
        closeSubNav();
    }

    function closeSubNav() {
        setSubNavActive(false);
    }

    function openSubNav() {
        setSubNavActive(true);
    }

    return (
        <li
            className={classNames(subNavActive && subNavActiveClassName)}
            onBlur={closeSubNav}
            onFocus={openSubNav}
            onMouseOut={closeSubNav}
        >
            <Link
                to={item.uri}
                className={classNames(isNavActive(item.uri) && activeClassName)}
                onClick={onLinkClick}
                onFocus={openSubNav}
                onMouseOver={openSubNav}
            >
                {item.label}
            </Link>

            {!isEmpty(item.childItems?.nodes) && (
                <SubNav
                    activeClassName={activeClassName}
                    item={item}
                    onClick={onLinkClick}
                    openSubNav={openSubNav}
                />
            )}
        </li>
    );
}

interface IProps {
    activeClassName: string;
    item: IMenuItems;
    onClick?: (e: MouseEvent) => void;
    subNavActiveClassName?: string;
}
