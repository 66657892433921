import classNames from "classnames";
import { Alert, Button } from "components";
import { AuthContext, LoadingContext, ModalContext } from "context";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { IAuthentication } from "types";
import { toSentenceCase } from "utils";

import styles from "./Login.module.scss";

export function Login({ action }: IProps) {
    const { login, logout } = useContext(AuthContext);
    const { closeModal, disableModalCloseButton } = useContext(ModalContext);
    const { hasLoaded, isLoading } = useContext(LoadingContext);
    const [fieldUserName, setFieldUserName] = useState<string>("");
    const [fieldPassword, setFieldPassword] = useState<string>("");
    const [alert, setAlert] = useState<string>("");
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [loggedOut, setLoggedOut] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    useEffect(() => {
        if (hasLoaded && processing && (loggedIn || loggedOut)) {
            closeModal();
            onSubmitted();
            setLoggedIn(false);
            setLoggedOut(false);
        }
    }, [hasLoaded, loggedIn, loggedOut]);

    function handleChange(e: ChangeEvent<HTMLInputElement>, input: "username" | "password") {
        setAlert("");
        if (input === "username") setFieldUserName(e.target.value);
        if (input === "password") setFieldPassword(e.target.value);
    }

    function onSubmit() {
        setProcessing(true);
        disableModalCloseButton(true);
    }

    function onSubmitted() {
        setProcessing(false);
        disableModalCloseButton(false);
    }

    function validation() {
        let message = "";

        if (fieldUserName === "") message = "You must enter a Username";
        if (fieldPassword === "") message = "You must enter a Password";
        if (fieldUserName === "" && fieldPassword === "")
            message = "You must enter a Username and Password";

        if (message !== "") {
            setAlert(message);
            return false;
        }

        return true;
    }

    async function userLogin(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (validation()) {
            onSubmit();

            const response = await login(fieldUserName, fieldPassword);

            if (response) {
                if (response.success) {
                    isLoading();
                    setLoggedIn(true);
                } else {
                    setAlert(response.data);
                    onSubmitted();
                }
            }
        }
    }

    function userLogout() {
        onSubmit();
        logout();
        setLoggedOut(true);
    }

    return (
        <div className={styles.form}>
            {action === "login" ? (
                <>
                    <h3>Login</h3>
                    <p className={classNames(styles.intro, processing && styles.processing)}>
                        {processing ? (
                            <strong>Logging you in</strong>
                        ) : (
                            "Please enter your details to log into the site"
                        )}
                    </p>
                    {alert && <Alert type="error" message={toSentenceCase(alert)} />}
                    <form onSubmit={userLogin}>
                        <div className={styles.formGroup}>
                            <label htmlFor="input-email">Username</label>
                            <input
                                type="text"
                                className={styles.formControl}
                                id="input-email"
                                aria-describedby="emailHelp"
                                placeholder="Enter username"
                                onChange={(e) => handleChange(e, "username")}
                                disabled={processing}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="input-password">Password</label>
                            <input
                                type="password"
                                className={styles.formControl}
                                id="input-password"
                                placeholder="Password"
                                onChange={(e) => handleChange(e, "password")}
                                disabled={processing}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <Button type="submit" variant="primary" disabled={processing}>
                                Login
                            </Button>
                            <Button variant="secondary" onClick={closeModal} disabled={processing}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <h3>Logout</h3>
                    <p className={classNames(styles.intro, processing && styles.processing)}>
                        {processing ? (
                            <strong>Logging you out</strong>
                        ) : (
                            "Are you sure you wish to logout?"
                        )}
                    </p>
                    <div className={styles.formGroup}>
                        <Button variant="primary" onClick={userLogout}>
                            Logout
                        </Button>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

interface IProps {
    action: IAuthentication;
}
