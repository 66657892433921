import "styles/index.scss";

import {
    AuthProvider,
    LoadingProvider,
    MenuProvider,
    ModalProvider,
    ViewportProvider,
    WordpressProvider,
} from "context";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <LoadingProvider>
            <AuthProvider>
                <ViewportProvider>
                    <MenuProvider>
                        <WordpressProvider>
                            <ModalProvider>
                                <App />
                            </ModalProvider>
                        </WordpressProvider>
                    </MenuProvider>
                </ViewportProvider>
            </AuthProvider>
        </LoadingProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
