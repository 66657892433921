import {
    WPBlockAudio,
    WPBlockButtons,
    WPBlockCalendar,
    WPBlockCode,
    WPBlockColumns,
    WPBlockCover,
    WPBlockEmbed,
    WPBlockFile,
    WPBlockGallery,
    WPBlockImage,
    WPBlockLatestComments,
    WPBlockMediaText,
    WPBlockMore,
    WPBlockPreformatted,
    WPBlockPullquote,
    WPBlockQuote,
    WPBlockRSS,
    WPBlockSeparator,
    WPBlockSocialLinks,
    WPBlockTable,
    WPBlockTagCloud,
    WPBlockTextColumns,
    WPBlockVerse,
    WPBlockVideo,
    WPLink,
} from "components";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { INodeData } from "types";

function getWPBlock(node: INodeData) {
    const { attribs, data, name, parent, type } = node;

    return {
        WPBlockAudio: attribs?.class?.includes("wp-block-audio"),
        WPBlockButtons: attribs?.class?.includes("wp-block-buttons"),
        WPBlockCalendar: attribs?.class?.includes("wp-block-calendar"),
        WPBlockCode: attribs?.class?.includes("wp-block-code"),
        WPBlockColumns: attribs?.class?.includes("wp-block-columns"),
        WPBlockCover:
            attribs?.class?.includes("wp-block-cover") ||
            attribs?.class?.includes("wp-block-cover-image"),
        WPBlockEmbed: attribs?.class?.includes("wp-block-embed"),
        WPBlockFile: attribs?.class?.includes("wp-block-file") && name === "div",
        WPBlockGallery: attribs?.class?.includes("wp-block-gallery"),
        WPBlockImage: attribs?.class?.includes("wp-block-image"),
        WPBlockLatestComments: attribs?.class?.includes("wp-block-latest-comments"),
        WPBlockMediaText: attribs?.class?.includes("wp-block-media-text") && name === "div",
        WPBlockMore: data?.includes("more") && type === "comment",
        WPBlockPreformatted: attribs?.class?.includes("wp-block-preformatted"),
        WPBlockPullquote: attribs?.class?.includes("wp-block-pullquote"),
        WPBlockQuote: attribs?.class?.includes("wp-block-quote"),
        WPBlockRSS: attribs?.class?.includes("wp-block-rss"),
        WPBlockSeparator: attribs?.class?.includes("wp-block-separator"),
        WPBlockSocialLinks: attribs?.class?.includes("wp-block-social-links"),
        WPBlockTable: attribs?.class?.includes("wp-block-table"),
        WPBlockTagCloud: attribs?.class?.includes("wp-block-tag-cloud"),
        WPBlockTextColumns: attribs?.class?.includes("wp-block-text-columns"),
        WPBlockVerse: attribs?.class?.includes("wp-block-verse"),
        WPBlockVideo: attribs?.class?.includes("wp-block-video"),
        WPLink: !parent?.attribs?.class?.includes("wp-block-file") && name === "a",
    };
}

function transform(node: INodeData, index: number) {
    const { name, parent } = node;
    const key = `${name}=${index}`;

    if (getWPBlock(node).WPBlockAudio)
        return <WPBlockAudio index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockButtons)
        return <WPBlockButtons index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockCalendar)
        return <WPBlockCalendar index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockCode)
        return <WPBlockCode index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockColumns)
        return <WPBlockColumns index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockCover)
        return <WPBlockCover index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockEmbed)
        return <WPBlockEmbed index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockFile)
        return <WPBlockFile index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockGallery)
        return <WPBlockGallery index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockImage)
        return <WPBlockImage index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockLatestComments)
        return <WPBlockLatestComments index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockMediaText)
        return <WPBlockMediaText index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockMore) return <WPBlockMore key={key} />;
    if (getWPBlock(node).WPBlockPreformatted)
        return <WPBlockPreformatted index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockPullquote)
        return <WPBlockPullquote index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockQuote)
        return <WPBlockQuote index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockRSS)
        return <WPBlockRSS index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockSeparator)
        return <WPBlockSeparator index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockSocialLinks)
        return <WPBlockSocialLinks index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockTable)
        return <WPBlockTable index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockTagCloud)
        return <WPBlockTagCloud index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockTextColumns)
        return <WPBlockTextColumns index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockVerse)
        return <WPBlockVerse index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPBlockVideo)
        return <WPBlockVideo index={index} key={key} node={node} transform={transform} />;
    if (getWPBlock(node).WPLink || getWPBlock(node).WPBlockImage)
        return <WPLink index={index} key={key} node={node} transform={transform} />;

    return parent ? (
        convertNodeToElement(node, index, transform)
    ) : (
        <div className="max-width" key={key}>
            {convertNodeToElement(node, index, transform)}
        </div>
    );
}

export function WPContent({ content }: IProps) {
    return (
        <>
            {ReactHtmlParser(content, {
                transform,
            })}
        </>
    );
}

interface IProps {
    content: string;
}
