import { axiosInstance } from "api";
import { TAuthToken } from "types";

function transformResponse(response: string) {
    const { data, errors } = JSON.parse(response);

    if (errors) {
        return null;
    }

    const { pageBy: page } = data;
    if (page?.isFrontPage) delete page.title;

    return {
        page,
        status: !page || page.title === "Restricted content" ? "denied" : "loaded",
    };
}

export async function getPageByUri(uri: string, authToken?: TAuthToken) {
    const response = await axiosInstance({
        data: {
            query: `query Page {
                pageBy(uri: "${uri}") {
                  title
                  content
                  isFrontPage
                  modified
                }
              }`,
            variables: {},
        },
        headers: {
            Authorization: `Bearer ${authToken || ""}`,
            "content-type": "application/json",
        },
        transformResponse: [transformResponse],
    });

    return response.data;
}
