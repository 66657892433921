import { axiosInstance } from "api";

function transformResponse(response: string) {
    const {
        data: { login },
        errors,
    } = JSON.parse(response);

    return {
        data: errors ? errors[0].message : login,
        success: !errors,
    };
}

export async function getLogin(username: string, password: string) {
    const response = await axiosInstance({
        data: {
            query: `mutation LoginUser {
                login(input: {
                  clientMutationId: "uniqueId",
                  username: "${username}",
                  password: "${password}"
                }) {
                  authToken
                  user {
                    id
                    name
                  }
                }
              }`,
            variables: {},
        },
        headers: { "content-type": "application/json" },

        transformResponse: [transformResponse],
    });

    return response.data;
}
