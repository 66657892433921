/* eslint-disable sort-keys */
import { sassVars } from "utils";

export const defaultViewport = {
    breakpoint: "",

    // Is a viewport
    isMobileOnly: true,
    isMobileLandscapeOnly: false,
    isTabletOnly: false,
    isDesktopOnly: false,
    isWidescreenOnly: false,
    isSuperWidescreenOnly: false,

    // Is greater than a viewport
    isMobileUp: false,
    isMobileLandscapeUp: false,
    isTabletUp: false,
    isDesktopUp: false,
    isWidescreenUp: false,
    isSuperWidescreenUp: false,

    // Is a touch enabled device
    isTouchEnabled: false,
};

export function fetchViewport() {
    const {
        breakpointOnlyMobile,
        breakpointUpMobile,
        breakpointOnlyMobileLandScape,
        breakpointUpMobileLandScape,
        breakpointOnlyTablet,
        breakpointUpTablet,
        breakpointOnlyDesktop,
        breakpointUpDesktop,
        breakpointOnlyWidescreen,
        breakpointUpWidescreen,
        breakpointOnlySuperWidescreen,
        breakpointUpSuperWidescreen,
        touchEnabled,
    } = sassVars;

    const mobile = { label: "mobile", query: breakpointOnlyMobile };
    const mobileLandscape = { label: "mobile-landscape", query: breakpointOnlyMobileLandScape };
    const tablet = { label: "tablet", query: breakpointOnlyTablet };
    const desktop = { label: "desktop", query: breakpointOnlyDesktop };
    const widescreen = { label: "widescreen", query: breakpointOnlyWidescreen };
    const superWidescreen = { label: "super-widescreen", query: breakpointOnlySuperWidescreen };
    const items = [mobile, mobileLandscape, tablet, desktop, widescreen, superWidescreen];

    const viewport = {
        breakpoint: items.filter((item) => window.matchMedia(item.query).matches)[0].label,
        isMobileOnly: window.matchMedia(breakpointOnlyMobile).matches,
        isMobileLandscapeOnly: window.matchMedia(breakpointOnlyMobileLandScape).matches,
        isTabletOnly: window.matchMedia(breakpointOnlyTablet).matches,
        isDesktopOnly: window.matchMedia(breakpointOnlyDesktop).matches,
        isWidescreenOnly: window.matchMedia(breakpointOnlyWidescreen).matches,
        isSuperWidescreenOnly: window.matchMedia(breakpointOnlySuperWidescreen).matches,
        isMobileUp: window.matchMedia(breakpointUpMobile).matches,
        isMobileLandscapeUp: window.matchMedia(breakpointUpMobileLandScape).matches,
        isTabletUp: window.matchMedia(breakpointUpTablet).matches,
        isDesktopUp: window.matchMedia(breakpointUpDesktop).matches,
        isWidescreenUp: window.matchMedia(breakpointUpWidescreen).matches,
        isSuperWidescreenUp: window.matchMedia(breakpointUpSuperWidescreen).matches,
        isTouchEnabled: window.matchMedia(touchEnabled).matches,
    };

    return viewport;
}
