import { IAlert } from "types";

import styles from "./Alert.module.scss";

interface IProps {
    message: string;
    type: IAlert;
}

export function Alert({ message, type }: IProps) {
    return <div className={styles[`${type}-alert`]}>{message}</div>;
}
