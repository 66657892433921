import classNames from "classnames";
import { ReactNode, useRef } from "react";
import { IButtonType, IButtonVariant } from "types";

import styles from "./Button.module.scss";

export function Button({ children, disabled, overrideClassName, onClick, type, variant }: IProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);

    function onButtonClick() {
        if (onClick) onClick();
        buttonRef?.current?.blur();
    }

    return (
        <button
            className={classNames(styles.button, styles[variant || "primary"], overrideClassName)}
            disabled={disabled}
            onClick={onButtonClick}
            ref={buttonRef}
            type={type || "button"}
        >
            {children}
        </button>
    );
}

interface IProps {
    children: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    overrideClassName?: string;
    type?: IButtonType;
    variant?: IButtonVariant;
}
