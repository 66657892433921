import { getPageByUri } from "api";
import { AuthContext, LoadingContext, WordpressContext } from "context";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IPageData } from "types";
import { isDataLoading, setDocumentTitle } from "utils";

const defaultPageData: IPageData = {
    hash: null,
    page: null,
    status: "loading",
};

function getIdFromHash(hash: string) {
    return hash.split("#")[1];
}

export function usePage() {
    const [pageData, setPageData] = useState<IPageData>(defaultPageData);
    const { authToken, logout, user } = useContext(AuthContext);
    const { hasThePageLoaded } = useContext(LoadingContext);
    const { setPrimaryCoverStatus } = useContext(WordpressContext);
    const { hash, pathname } = useLocation();

    useEffect(() => {
        (async () => {
            setPageData({ ...pageData, status: "loading" });
            isDataLoading(true);

            const response = await getPageByUri(pathname, authToken);

            if (!response) {
                logout(true);
            } else {
                const responseWithHash = { ...response, hash: getIdFromHash(hash) || null };
                if (responseWithHash.page?.title) setDocumentTitle(responseWithHash.page.title);
                setPageData(responseWithHash);
                hasThePageLoaded(true);
                setPrimaryCoverStatus(response.page?.content);
                isDataLoading(false);
            }
        })();
    }, [hash, pathname, user]);

    return pageData as IPageData;
}
