import classNames from "classnames";
import moment from "moment";

import styles from "./Footer.module.scss";

export function Footer() {
    return (
        <div className={styles.footer}>
            <footer className="max-width">
                <p className={styles.address}>
                    &copy;{moment().year()} Horsham Model Railway Club -{" "}
                    <a href="mailto:info@horshammrc.org.uk">info@horshammrc.org.uk</a>
                    <br />
                    <small>
                        St Leonard&apos;s Church, Cambridge Road, Horsham, West Sussex, RH13 5ED
                    </small>
                </p>
                <ul className={classNames(styles.social, "wp-block-social-links")}>
                    <li className={styles.socialText}>Follow us:</li>
                    <li
                        className={classNames(
                            "wp-social-link",
                            "wp-social-link-facebook",
                            "wp-block-social-link",
                        )}
                    >
                        <a
                            href="https://www.facebook.com/HorshamMRC/"
                            aria-label="Facebook"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="https://www.w3.org/2000/svg"
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                            >
                                <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z" />
                            </svg>
                        </a>
                    </li>
                </ul>
            </footer>
        </div>
    );
}
