import "./WPBlockAudio.scss";

import { convertNodeToElement, Transform } from "react-html-parser";
import { INodeData } from "types";

export function WPBlockAudio({ index, node, transform }: IProps) {
    return node.parent ? (
        convertNodeToElement(node, index, transform)
    ) : (
        <div className="max-width">{convertNodeToElement(node, index, transform)}</div>
    );
}

interface IProps {
    index: number;
    node: INodeData;
    transform: Transform;
}
