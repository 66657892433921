import { axiosInstance } from "api";
import { IMenu, IMenuItems, TAuthToken } from "types";

function getMenu(menus: IMenu[], slug: string) {
    const filtered = menus.filter((menu: IMenu) => menu.slug === slug)[0].menuItems.nodes;
    const authChecked = filtered.filter(
        (menuItem: IMenuItems) => menuItem.label !== "Restricted content",
    );

    return authChecked;
}

function transformResponse(response: string) {
    const { data, errors } = JSON.parse(response);

    if (errors) {
        return null;
    }

    const menus = data.menus.nodes;

    return {
        mainMenu: getMenu(menus, "main-menu"),
        topMenu: getMenu(menus, "top-menu"),
    };
}

export async function getMenus(authToken: TAuthToken) {
    const response = await axiosInstance({
        data: {
            query: `query Menus {
                menus(first: 2) {
                    nodes {
                        id
                        menuItems(where: {parentId: "null"}) {
                            nodes {
                                childItems {
                                    nodes {
                                        label
                                        uri
                                    }
                                }
                                label
                                uri
                            }
                        }
                        slug
                    }
                }
            }`,
            variables: {},
        },
        headers: {
            Authorization: `Bearer ${authToken || ""}`,
            "content-type": "application/json",
        },
        transformResponse: [transformResponse],
    });

    return response.data;
}
