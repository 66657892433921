import LogoDarkMD from "assets/img/logo-dark-md.png";
import LogoDarkXS from "assets/img/logo-dark-xs.png";
import LogoMD from "assets/img/logo-md.png";
import LogoXS from "assets/img/logo-xs.png";
import classNames from "classnames";
import { SlideInMenu, TabbedMenu, TopMenu, UserStatus } from "components";
import { ViewportContext, WordpressContext } from "context";
import { useContext } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.scss";

export function Header() {
    const { isDesktopUp, isTabletUp, isTouchEnabled } = useContext(ViewportContext);
    const { hasPrimaryCover } = useContext(WordpressContext);
    const title = {
        heading: "Horsham Model Railway Club",
        sub: "Railway Club",
    };

    return (
        <div className={classNames(styles.header, hasPrimaryCover && styles.primaryCover)}>
            <header>
                <Link to="/" className={styles.logo}>
                    <>
                        {hasPrimaryCover ? (
                            <img
                                src={isTabletUp ? LogoDarkMD : LogoDarkXS}
                                alt="Horsham Model Railway Club"
                            />
                        ) : (
                            <img
                                src={isTabletUp ? LogoMD : LogoXS}
                                alt="Horsham Model Railway Club"
                            />
                        )}
                    </>
                </Link>
                <p>{title.heading}</p>
                <div className={styles.headerRight}>
                    {!isTouchEnabled && isDesktopUp && <TopMenu />}
                    <UserStatus />
                </div>
                {!isTouchEnabled && isDesktopUp && <TabbedMenu />}
                {(isTouchEnabled || !isDesktopUp) && <SlideInMenu />}
            </header>
        </div>
    );
}
