import "./WPBlockCover.scss";

import classnames from "classnames";
import { WordpressContext } from "context";
import toStyle from "css-to-style";
import { CSSProperties, Fragment, useContext } from "react";
import { convertNodeToElement, Transform } from "react-html-parser";
import { INodeData } from "types";
import { keys } from "utils";

export function WPBlockCover({ index, node, transform }: IProps) {
    const { hasPrimaryCover } = useContext(WordpressContext);
    const { attribs, children } = node;
    const className = classnames(attribs?.class, hasPrimaryCover && index === 0 && "primary-cover");
    const style = attribs.style ? (toStyle(attribs.style) as CSSProperties) : undefined;

    return (
        <div className={className} style={style}>
            {children.map((child: INodeData, childIndex: number) => (
                <Fragment key={keys("wp-block-cover", childIndex)}>
                    {" "}
                    {convertNodeToElement(child, index, transform)}
                </Fragment>
            ))}
        </div>
    );
}

interface IProps {
    index: number;
    node: INodeData;
    transform: Transform;
}
