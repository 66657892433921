import classNames from "classnames";
import { WPContent } from "components";
import { LoadingContext } from "context";
import { usePage } from "hooks";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { TPage } from "types";

import styles from "./WPRoot.module.scss";

export function WPRoot() {
    const [currentPage, setCurrentPage] = useState<TPage>(null);
    const { hasLoaded } = useContext(LoadingContext);
    const { hash, page, status } = usePage();

    useEffect(() => {
        if (hasLoaded && page && status === "loaded") setCurrentPage(page);
    }, [hasLoaded, page]);

    useEffect(() => {
        if (hash && page) {
            const elmnt = document.getElementById(hash);
            elmnt?.scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    }, [currentPage]);

    return (
        <>
            {status === "denied" ? (
                <Navigate to="/" />
            ) : (
                <div
                    id="wp-root"
                    className={classNames(styles.wpRoot, status === "loading" && styles.loading)}
                >
                    {currentPage && (
                        <>
                            {currentPage.title && (
                                <div className="max-width">
                                    <h1>{currentPage.title}</h1>
                                </div>
                            )}
                            <WPContent content={currentPage.content} />
                        </>
                    )}
                </div>
            )}
        </>
    );
}
