import { ModalContext } from "context";
import { isEmpty, last } from "lodash";
import { Fragment, MouseEvent, useContext } from "react";
import { convertNodeToElement, Transform } from "react-html-parser";
import { Link } from "react-router-dom";
import { INodeData } from "types";
import { dataURL, keys } from "utils";

export function WPLink({ index, node, transform }: IProps) {
    const { closeModal, openModal } = useContext(ModalContext);
    const { attribs, children } = node;
    const href = last(attribs.href?.split(dataURL)) as string;
    const className = "";

    function enlargeImage(e: MouseEvent, src?: string) {
        e.preventDefault();
        openModal(<img alt="" src={src} onClick={closeModal} role="presentation" />, "media");
    }

    if (isEmpty(children)) return null;

    return (
        <>
            {attribs.href?.includes(dataURL) ? (
                <>
                    {attribs.href.includes("/wp-content/uploads") ? (
                        <a
                            href={attribs?.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={className}
                            onClick={(e) => enlargeImage(e, attribs.href)}
                        >
                            {children.map((child: INodeData, childIndex: number) => (
                                <Fragment key={keys("wp-content-upload", childIndex)}>
                                    {convertNodeToElement(child, index, transform)}
                                </Fragment>
                            ))}
                        </a>
                    ) : (
                        <>
                            {attribs.target === "_blank" ? (
                                <a
                                    href={attribs?.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={className}
                                >
                                    {children.map((child: INodeData, childIndex: number) => (
                                        <Fragment key={keys("external-link", childIndex)}>
                                            {convertNodeToElement(child, index, transform)}
                                        </Fragment>
                                    ))}
                                </a>
                            ) : (
                                <Link to={href} className={className}>
                                    {children.map((child: INodeData, childIndex: number) => (
                                        <Fragment key={keys("internal-link", childIndex)}>
                                            {convertNodeToElement(child, index, transform)}
                                        </Fragment>
                                    ))}
                                </Link>
                            )}
                        </>
                    )}
                </>
            ) : (
                <a href={attribs?.href} target={attribs?.target || "_blank"} className={className}>
                    {children.map((child: INodeData, childIndex: number) => (
                        <Fragment key={keys("external-link", childIndex)}>
                            {convertNodeToElement(child, index, transform)}
                        </Fragment>
                    ))}
                </a>
            )}
        </>
    );
}

interface IProps {
    index: number;
    node: INodeData;
    transform: Transform;
}
