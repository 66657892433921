import { createContext, ReactNode, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { INodeData } from "types";

export const WordpressContext = createContext({
    hasPrimaryCover: false,
    setPrimaryCoverStatus: (content: string) => content,
});

export function WordpressProvider({ children }: IProps) {
    const [hasPrimaryCover, setHasPrimaryCover] = useState<boolean>(false);

    function setPrimaryCoverStatus(content: string) {
        let primaryCover = false;
        let counter = 0;

        ReactHtmlParser(content, {
            transform: (node: INodeData) => {
                const { attribs } = node;
                if (attribs?.class?.includes("wp-block-cover") && counter === 0)
                    primaryCover = true;

                counter += 1;
            },
        });

        setHasPrimaryCover(primaryCover);
    }

    const value = useMemo(
        () => ({
            hasPrimaryCover,
            setPrimaryCoverStatus,
        }),
        [hasPrimaryCover],
    ) as IValue;

    return <WordpressContext.Provider value={value}>{children}</WordpressContext.Provider>;
}

interface IProps {
    children: ReactNode;
}

interface IValue {
    hasPrimaryCover: boolean;
    setPrimaryCoverStatus: (value: string) => string;
}
