import "./WPBlockMore.scss";

import classNames from "classnames";
import { useState } from "react";

export function WPBlockMore() {
    const [visible, setVisible] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>("Read more");

    function toggle() {
        setVisible(!visible);
        setButtonText(visible ? "Read more" : "Read less");
    }

    return (
        <div className={classNames("max-width", "wp-block-more", !visible && "closed")}>
            <button onClick={toggle}>{buttonText}</button>
        </div>
    );
}
