import { NavLink } from "components";
import { MouseEvent } from "react";
import { IMenuItems } from "types";
import { keys } from "utils";

export function Nav(props: IProps) {
    const {
        activeClassName,
        items,
        onClick,
        overrideClassName,
        subNavActiveClassName = "active",
    } = props;

    if (!items) return null;

    return (
        <ul className={overrideClassName}>
            {items.map(
                (item: IMenuItems) =>
                    !item.uri.includes("page_id") && (
                        <NavLink
                            key={keys("nav", item.uri)}
                            activeClassName={activeClassName}
                            item={item}
                            onClick={onClick}
                            subNavActiveClassName={subNavActiveClassName}
                        />
                    ),
            )}
        </ul>
    );
}

interface IProps {
    activeClassName: string;
    items: IMenuItems[] | null;
    onClick?: (e: MouseEvent) => void;
    overrideClassName: string;
    subNavActiveClassName?: string;
}
