import classNames from "classnames";
import { ModalContext } from "context";
import React, { useContext, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { sassVars } from "utils";

import styles from "./Modal.module.scss";

export function Modal() {
    const {
        closeModal,
        isModalVisible,
        modalCloseButtonDisabled,
        modalContent,
        modalFormat,
        removeModalContent,
    } = useContext(ModalContext);
    const nodeRef = useRef(null);
    const [opening, setOpening] = useState<boolean>(false);
    const [closing, setClosing] = useState<boolean>(false);

    const { modalAnimation } = sassVars;

    function toggleTransitioning() {
        closeModal();
    }

    function onEntering() {
        setOpening(true);
    }

    function onEntered() {
        //
    }

    function onExit() {
        setOpening(false);
        setClosing(true);
    }

    function onExited() {
        setClosing(false);
        removeModalContent();
    }

    return (
        <CSSTransition
            in={isModalVisible}
            nodeRef={nodeRef}
            timeout={modalAnimation}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExited={onExited}
        >
            <div
                className={classNames(
                    styles.modalWrapper,
                    opening && styles.modalOpening,
                    closing && styles.modalClosing,
                )}
                ref={nodeRef}
            >
                <div className={styles.modal} onClick={toggleTransitioning} role="presentation">
                    <div
                        className={classNames(
                            styles.modalContent,
                            opening && styles.modalContentOpening,
                            closing && styles.modalContentClosing,
                            modalFormat && styles[modalFormat],
                        )}
                        onClick={(e) => e.stopPropagation()}
                        role="presentation"
                    >
                        <button
                            className={styles.close}
                            onClick={toggleTransitioning}
                            disabled={modalCloseButtonDisabled}
                        >
                            Close
                        </button>
                        {modalContent}
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}
