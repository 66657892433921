/* eslint-disable sort-keys */
import { IENVS } from "types";

const ENVS: IENVS = {
    "https://192.168.1.15:3205": {
        dataURL: "https://horshammrc2.localhost.imonitmedia.com",
        env: "development",
        location: "local",
    },
    "https://localhost:3205": {
        dataURL: "https://horshammrc2.localhost.imonitmedia.com",
        env: "development",
        location: "local",
    },
    "https://horshammrc2:3205": {
        dataURL: "https://horshammrc2.localhost.imonitmedia.com",
        env: "development",
        location: "local",
    },
    "https://horshammrc2.localhost.imonitmedia.com": {
        dataURL: "https://horshammrc2.localhost.imonitmedia.com",
        env: "production",
        location: "local",
    },
    "https://horshammrc2.staging.imonitmedia.com": {
        dataURL: "https://horshammrc2.staging.imonitmedia.com",
        env: "production",
        location: "staging",
    },
    "https://horshammrc2.imonitmedia.com": {
        dataURL: "https://horshammrc2.imonitmedia.com",
        env: "production",
        location: "production",
    },
    "https://horshammrc.org.uk": {
        dataURL: "https://www.horshammrc.org.uk",
        env: "production",
        location: "live",
    },
    "https://www.horshammrc.org.uk": {
        dataURL: "https://www.horshammrc.org.uk",
        env: "production",
        location: "live",
    },
};

export const { dataURL } = ENVS[window.location.origin];
