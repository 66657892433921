import { createContext, ReactNode, useMemo, useState } from "react";

export const ModalContext = createContext<IContext>({
    addModalContent: () => null,
    closeModal: () => null,
    disableModalCloseButton: (status: boolean) => status,
    isModalVisible: false,
    modalCloseButtonDisabled: false,
    modalContent: null,
    modalFormat: "default",
    openModal: (content: ReactNode, format?: TFormat) => content && format,
    removeModalContent: () => null,
});

export function ModalProvider({ children }: IProps) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalCloseButtonDisabled, setModalCloseButtonDisabled] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [modalFormat, setModalFormat] = useState<TFormat>("default");

    function addModalContent(content: ReactNode) {
        setModalContent(content);
    }

    function closeModal() {
        setIsModalVisible(false);
        if (modalCloseButtonDisabled) setModalCloseButtonDisabled(false);
    }

    function disableModalCloseButton(status: boolean) {
        setModalCloseButtonDisabled(status);
    }

    function openModal(content: ReactNode, format?: TFormat) {
        setModalFormat(format || "default");
        setModalContent(content);
        setIsModalVisible(true);
    }

    function removeModalContent() {
        setModalFormat("default");
        setModalContent(null);
    }

    const value = useMemo(
        () => ({
            addModalContent,
            closeModal,
            disableModalCloseButton,
            isModalVisible,
            modalCloseButtonDisabled,
            modalContent,
            modalFormat,
            openModal,
            removeModalContent,
        }),
        [isModalVisible, modalCloseButtonDisabled, modalContent],
    ) as IContext;

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

type TFormat = "media" | "default";

interface IContext {
    addModalContent: (_content: ReactNode) => void;
    closeModal: () => void;
    disableModalCloseButton: (status: boolean) => void;
    isModalVisible: boolean;
    modalCloseButtonDisabled: boolean;
    modalContent: ReactNode;
    modalFormat: TFormat;
    openModal: (content: ReactNode, format?: TFormat) => void;
    removeModalContent: () => void;
}

interface IProps {
    children: ReactNode;
}
