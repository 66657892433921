export const storage = {
    get: (key: string) => {
        const val = window.localStorage.getItem(key);

        try {
            return val && JSON.parse(val);
        } catch {
            console.error(`Stored value '${val}' at '${key}' could not be parsed by JSON.parse.`);
            return undefined;
        }
    },
    remove: (key: string) => {
        window.localStorage.removeItem(key);
    },
    set: (key: string, value: string | boolean) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    },
};
