import { useLocation } from "react-router-dom";
import { SITE_TITLE } from "static";

export function isNavActive(uri: string) {
    const { pathname } = useLocation();
    return pathname === uri;
}

export function isDataLoading(loading: boolean) {
    document.body.className = loading ? "loading" : "";
}

export function keys(...args: Array<string | number>) {
    return args.join("-");
}

export function setDocumentTitle(title?: string) {
    document.title = `${SITE_TITLE}${title ? ` - ${title}` : ""}`;
}
