import LogoDarkMD from "assets/img/logo-dark-md.png";
import LogoDarkXS from "assets/img/logo-dark-xs.png";
import classNames from "classnames";
import { LoadingContext, ViewportContext } from "context";
import { useContext, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { sassVars } from "utils";

import styles from "./InitialLoading.module.scss";

export function InitialLoading() {
    const { hasLoaded, initialLoading } = useContext(LoadingContext);
    const { isTabletUp } = useContext(ViewportContext);
    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true);
    const [closing, setClosing] = useState<boolean>(false);
    const nodeRef = useRef(null);

    const { initialLoaderAnimation } = sassVars;

    useEffect(() => {
        if (hasLoaded) setIsLoaderVisible(false);
    }, [hasLoaded]);

    function onExit() {
        setClosing(true);
    }

    function onExited() {
        initialLoading(false);
    }

    return (
        <CSSTransition
            in={isLoaderVisible}
            nodeRef={nodeRef}
            timeout={initialLoaderAnimation}
            onExit={onExit}
            onExited={onExited}
        >
            <div
                className={classNames(styles.loading, closing && styles.loadingClosing)}
                ref={nodeRef}
            >
                <div className={classNames(styles.content, closing && styles.contentClosing)}>
                    {isTabletUp ? (
                        <img
                            src={LogoDarkMD}
                            className={styles.logo}
                            alt="Horsham Model Railway Club"
                        />
                    ) : (
                        <img
                            src={LogoDarkXS}
                            className={styles.logo}
                            alt="Horsham Model Railway Club"
                        />
                    )}
                    <p>Horsham Model Railway Club</p>
                    <small>We are loading your trains</small>
                </div>
            </div>
        </CSSTransition>
    );
}
