import classNames from "classnames";
import { HamBurger, Nav } from "components";
import { AuthContext, MenuContext } from "context";
import { MouseEvent, useContext, useState } from "react";

import styles from "./SlideInMenu.module.scss";

export function SlideInMenu() {
    const { user } = useContext(AuthContext);
    const { mainMenu, topMenu } = useContext(MenuContext);
    const [open, setOpen] = useState<boolean>(false);

    function onToggleNav(e: MouseEvent) {
        e.stopPropagation();

        if (!open) document.getElementById("menu-container")?.scrollTo(0, 0);
        document.body.style.overflow = open ? "visible" : "hidden";

        setOpen(!open);
    }

    return (
        <nav
            className={classNames(styles.slideInMenu, open && styles.open)}
            onClick={onToggleNav}
            role="presentation"
        >
            <HamBurger menuOpen={open} overrideClassName={styles.toggle} onClick={onToggleNav} />

            <div className={styles.menuContainer} id="menu-container">
                {user && (
                    <div className={styles.welcome}>
                        Currently logged in as: <strong>{user.name}</strong>
                    </div>
                )}
                <Nav
                    activeClassName={styles.active}
                    items={topMenu}
                    onClick={onToggleNav}
                    overrideClassName={styles.nav}
                />
                <Nav
                    activeClassName={styles.active}
                    items={mainMenu}
                    onClick={onToggleNav}
                    overrideClassName={styles.nav}
                />
            </div>
        </nav>
    );
}
