import { getLogin } from "api";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { TAuthToken, TLogin, TUser } from "types";
import { storage } from "utils";

import { LoadingContext } from "./loading";

export const AuthContext = createContext<IValue>({
    authToken: null,
    login: () => null,
    logout: () => null,
    user: null,
});

export function AuthProvider({ children }: IProps) {
    const { isLoading } = useContext(LoadingContext);
    const [currentUser, setCurrentUser] = useState<TUser>(storage.get("user"));
    const [currentAuthToken, setCurrentAuthToken] = useState<TAuthToken>(storage.get("authToken"));

    async function login(username: string, password: string) {
        const { data, success } = await getLogin(username, password);

        if (success) {
            const { authToken, user } = data;
            storage.set("user", user);
            storage.set("authToken", authToken);
            setCurrentUser(user);
            setCurrentAuthToken(authToken);
        }

        return { data, success };
    }

    function logout(forced?: boolean) {
        isLoading(forced);
        storage.remove("user");
        storage.remove("authToken");
        setCurrentUser(null);
        setCurrentAuthToken(null);
    }

    const value = (useMemo(
        () => ({
            authToken: currentAuthToken,
            login,
            logout,
            user: currentUser,
        }),
        [currentAuthToken, currentUser],
    ) as unknown) as IValue;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

interface IProps {
    children: ReactNode;
}

interface IValue {
    login: (username: string, password: string) => TLogin;
    logout: (forced?: boolean) => void;
    authToken: TAuthToken;
    user: TUser;
}
