import { createContext, ReactNode, useEffect, useState } from "react";
import { defaultViewport, fetchViewport } from "utils";

export const ViewportContext = createContext(defaultViewport);

export function ViewportProvider({ children }: IProps) {
    const [currentViewport, setCurrentViewport] = useState(defaultViewport);
    const [newViewport, setNewViewport] = useState(defaultViewport);

    const onViewportChange = () => setNewViewport(fetchViewport());

    useEffect(() => {
        window.addEventListener("resize", onViewportChange);
        onViewportChange();

        return () => window.removeEventListener("resize", onViewportChange);
    }, []);

    useEffect(() => setCurrentViewport(newViewport), [newViewport.breakpoint]);

    return <ViewportContext.Provider value={currentViewport}>{children}</ViewportContext.Provider>;
}

interface IProps {
    children: ReactNode;
}
