import "./WPBlockFile.scss";

import { convertNodeToElement, Transform } from "react-html-parser";
import { INodeData } from "types";

export function WPBlockFile({ index, node, transform }: IProps) {
    function updateNode() {
        // node.children.forEach((child) => {
        //   if (child.attribs.class?.includes("wp-block-file__button")) {
        //     child.attribs.class = "btn btn-outline-warning";
        //   } else {
        //     child.attribs.class = "link";
        //   }
        // });

        return node;
    }

    return node.parent ? (
        convertNodeToElement(updateNode(), index, transform)
    ) : (
        <div className="max-width">{convertNodeToElement(updateNode(), index, transform)}</div>
    );
}

interface IProps {
    index: number;
    node: INodeData;
    transform: Transform;
}
