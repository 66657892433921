import "App.scss";

import {
    CookieBanner,
    Footer,
    Header,
    InitialLoading,
    Modal,
    ScrollToTop,
    WPRoot,
} from "components";
import { LoadingContext } from "context";
import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
    const { hasLoaded, initialLoad } = useContext(LoadingContext);
    return (
        <>
            <BrowserRouter basename="/">
                <Header />
                <div className="main">
                    <main>
                        <Routes>
                            <Route path="*" element={<WPRoot />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
                <Modal />
            </BrowserRouter>
            {hasLoaded && <CookieBanner />}
            {initialLoad === "yes" && <InitialLoading />}
            <ScrollToTop />
        </>
    );
}

export default App;
