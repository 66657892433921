import { Button, Login } from "components";
import { AuthContext, LoadingContext, ModalContext } from "context";
import { useContext, useEffect, useState } from "react";
import { TUser } from "types";

import styles from "./UserStatus.module.scss";

export function UserStatus() {
    const [currentUser, setCurrentUser] = useState<TUser>(null);
    const { user } = useContext(AuthContext);
    const { openModal } = useContext(ModalContext);
    const { hasLoaded } = useContext(LoadingContext);

    useEffect(() => {
        if (hasLoaded) setCurrentUser(user);
    }, [hasLoaded]);

    function logout() {
        openModal(<Login action="logout" />);
    }

    function login() {
        openModal(<Login action="login" />);
    }

    return (
        <div className={styles.userStatus}>
            {currentUser ? (
                <>
                    <span>
                        Welcome back
                        <br />
                        {currentUser.name}
                    </span>
                    <Button onClick={logout}>Logout</Button>
                </>
            ) : (
                <Button onClick={login}>Login</Button>
            )}
        </div>
    );
}
